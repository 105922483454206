import React, {useEffect, useState} from "react";
import './style.scss'
import img from '../../../assets/images/logo-green.svg';
import logoUsn from '../../../assets/images/landing/logo-usn.png';
export const Header = () => {
    const [showDisclosure, setShowDisclosure] = useState(false);

    const scrollHandler = () => {
        let scrollTop = window.scrollY,
            elHeader = document.getElementsByClassName('header');
        if(elHeader.length > 0) {
            if(scrollTop > 0) {
                elHeader[0].classList.add('header-fixed');
            } else {
                elHeader[0].classList.remove('header-fixed');
            }
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [],)
    return <>
        <header className="header header-usn">
            <div className="header-inner">
                <div className="container ">
                    <div className="row">
                        <div className="header-left col">
                            <a href="/" className="logo">
                                <img src={img} alt=""/>
                            </a>
                            <div className="usn-logo">
                                <img src={logoUsn} alt=""/>
                            </div>
                        </div>
                        <div className="header-right col">
                            <button className='show-usn-disclosure' onClick={()=>{setShowDisclosure(!showDisclosure)}}>Advertiser Disclosure</button>
                            {showDisclosure && <div className="tooltip-popup">
                                <button className="close-tooltip" onClick={()=>{setShowDisclosure(false)}}><span className='icon-close'/></button>
                                <div className="tooltip-popup-content">
                                    <h3>Advertiser Disclosure</h3>
                                    <p className='little text-grey'>U.S. News & World Report takes an unbiased approach to our recommendations.  When you use our links to buy products, we may earn a commission but that in no way affects our editorial independence.</p>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>

            </div>
        </header>
    </>
}
