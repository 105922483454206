import React, {useEffect} from "react";
import './style.scss'
import {AppModal} from "./app-modal";
import {useStore} from "../../core/providers/global_provider";
import {observable} from "mobx";
import {observer} from "mobx-react";

export const GlobalModal: React.FunctionComponent = observer(() => {

    const {modalResetData, modal} = useStore();

    const onClose = () => {
        modalResetData();
    }

    return <>
        {modal.child && <AppModal blur={modal.blur} onClose={()=>onClose()} >
            {modal.child}
        </AppModal>}
    </>
});
