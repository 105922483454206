import React from "react";
import './style.scss';
import {Link} from "react-router-dom";
import {Subscribe} from "../subscribe";
import {
    aboutUrl,
    blogUrl, californiaPersonalInfoUrl, californiaPrivacyNoteUrl, contactUrl, employerUrl,
    faqUrl, lenderUrl, privacyUrl,
    studentLearnUrl,
    studentManagementUrl,
    studentRefiUrl,
    studentUrl, termsUrl, universitiesUrl, usnPrivacyUrl, usnTermsUrl
} from "../../Routes";

export const Footer = () => {
    return <>
        <footer className="footer">
            <div className="footer-top">
                <div className="container">
                    <hr/>
                        <div className="row">
                            <div className="footer-col1 col">
                                <h3 className="h4">Products</h3>
                                <ul className="footer-menu">
                                    <li>
                                        <a href={studentUrl} target='_blank'>
                                            <span>Student Loans</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={studentRefiUrl} target='_blank'>
                                            <span>Student Loan Refi</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={studentManagementUrl} target='_blank'>
                                            <span>Student Debt Management <i>Coming soon</i></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={studentLearnUrl} target='_blank'>
                                            <span>Learn and Earn <i>Coming soon</i></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-col2 col">
                                <h3 className="h4">Company</h3>
                                <ul className="footer-menu">
                                    <li>
                                        <a href={aboutUrl} className='hover-line' target='_blank'>
                                            <span>About Us</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={blogUrl} className='hover-line' target='_blank'>
                                            <span>Blog</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={faqUrl} className='hover-line' target='_blank'>
                                            <span>FAQs</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={contactUrl} className='hover-line' target='_blank'>
                                            <span>Contact Us</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-col3 col">
                                <h3 className="h4">Partner With Us </h3>
                                <ul className="footer-menu">
                                    <li>
                                        <a href={universitiesUrl} target='_blank'>
                                            <span>Universities</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={lenderUrl} target='_blank'>
                                            <span>Lenders</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={employerUrl} target='_blank'>
                                            <span>Employers</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-col4 col">
                                <h3 className="h4">Subscribe to The Nest</h3>
                                <p className="muted">The 3-minute newsletter with fresh takes on the financial news you
                                    need to start your day.</p>
                                <div className="subscribe-block">
                                    <Subscribe/>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col footer-bottom-copy">
                            <p className="copy">© 2021. Sparrow. All Rights Reserved.</p>
                        </div>
                        <div className="col footer-bottom-menu">
                            <ul className="menu">
                                <li>
                                    <a href={privacyUrl} className='hover-line' target='_blank'>
                                        <span>Privacy Policy</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={termsUrl} className='hover-line' target='_blank'>
                                        <span>Terms of Service</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col footer-bottom-social">
                            <ul className="social">
                                <li>
                                    <a href="https://www.instagram.com/sparrow.app/" target="_blank">
                                        <span className="icon-inst"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/sparrow-lending" target="_blank">
                                        <span className="icon-linkedin"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/Sparrow-102780281554881" target="_blank">
                                        <span className="icon-fb"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/SparrowLending" target="_blank">
                                        <span className="icon-tw"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col col-12 col-copy-usn">
                            <p className='copy'>Copyright 2021 © U.S. News & World Report L.P. <br/>
                                <a href={usnTermsUrl} target='_blank'>Terms & Conditions</a> | <a href={usnPrivacyUrl} target='_blank'>Privacy Policy</a> | <a
                                    href={californiaPrivacyNoteUrl} target='_blank'>California Privacy Notice</a> | <a
                                    href={californiaPersonalInfoUrl}>California Do Not Sell My Personal Information</a> Request</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
}
