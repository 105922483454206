import React from "react";
import {Link} from 'react-router-dom'
import './style.scss';
import partner1 from '../../../assets/images/landing/partners/Frame 1.png';
import partner2 from '../../../assets/images/landing/partners/Frame 2.png';
import partner3 from '../../../assets/images/landing/partners/Frame 3.png';
import partner4 from '../../../assets/images/landing/partners/Frame 4.png';
import partner5 from '../../../assets/images/landing/partners/Frame 5.png';
import partner6 from '../../../assets/images/landing/partners/Frame 6.png';
import partner7 from '../../../assets/images/landing/partners/Frame 7.png';
import partner8 from '../../../assets/images/landing/partners/Frame 8.png';
import SwiperCore, {Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';






export const PartnerSlider: React.FunctionComponent = (props) => {
    SwiperCore.use([Autoplay]);
    const params = {
        runCallbacksOnInit: true,
        speed: 4000,
        loop: true,
        freeMode: true,
        // rebuildOnUpdate: true,
        autoplay: {delay:0},
        onSwiper: (swiper)=>{

        }
    }
    return <>
        <Swiper
            className='partnerSlider'
            slidesPerView={'auto'}
            // data-swiper-autoplay = {"0"}
            {...params}
        >
            <SwiperSlide>
                <img src={partner1} alt=""/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={partner2} alt=""/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={partner3} alt=""/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={partner4} alt=""/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={partner5} alt=""/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={partner6} alt=""/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={partner7} alt=""/>
            </SwiperSlide>
            <SwiperSlide>
                <img src={partner8} alt=""/>
            </SwiperSlide>
        </Swiper>
    </>
}
