import React from "react";
import './style.scss';
import clearIcon from '../../../../assets/images/clear-input.svg';
import iconSucc from '../../../../assets/images/check.svg';
import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';
import {values} from "mobx";

interface IInputText {
    placeholder: string;
    type: string;
    name: string;
    label: string;
    value: string | number | any;
    maxLength?: number;
    minLength?: number;
    successIcon?: boolean;
    error?: string | null;
    onChange?: (any);
    onClear?: () => void;
    col?: number;
    prefix?: string;
    mask?: string;
    passwordType?: boolean;
    passwordShown?: boolean;
    showPassword?: () => void;
    isNumberMask?: boolean;
    allowZero?: boolean;
    readonly?: boolean;
    ref? : any;
}

export const InputText: React.FunctionComponent<IInputText> = ({allowZero = false, ...props}) => {

    return <div className={`form-col-${props.col ? props.col : 12} form-col`}>
        <div className={`${props.error && props.error.length ? 'error' : ''} form-group ${props.prefix ? 'has-prefix' : ''}`}>

            {/*{props.prefix && props.value && <span className="prefix">{props.prefix}</span>}*/}
            {(props.mask || props.isNumberMask)
                ? <NumberFormat
                    format={props.mask} allowLeadingZeros={false} allowNegative={false} isAllowed={(val) => allowZero && val.value.length == 1 ? true : val.floatValue !== 0}
                    value={props.value} thousandSeparator={true} onValueChange={(v) => props.onChange(v.floatValue, v.formattedValue, v.value)}
                    placeholder={props.placeholder}
                    prefix={props.prefix} fixedDecimalScale={true} decimalScale={0}
                    readOnly={props.readonly}
                />
                :
                <input type={props.type} className={`${props.value && props.value.length > 0 ? 'has-value': ''}`}
                       placeholder={props.placeholder}
                       value={props.value} minLength={props.minLength}
                       maxLength={props.maxLength}
                       required name={props.name}
                       onChange={props.onChange}
                />
            }
            <label>{props.label}</label>
            {typeof props.value == 'string' && props.value && props.value.length > 0 && !props.successIcon && !props.passwordType && !props.readonly &&
                <div className="form-group--navigation clear-btn" onClick={props.onClear}>
                    <img src={clearIcon} alt=""/>
                </div>
            }
            {props.passwordType && <div className="form-group--navigation" onClick={props.showPassword}>
                <div className={`${props.passwordShown ? 'active' : ''} toggle-password`}/>
            </div>}
            {props.error && props.error.length > 0 && <p className="error-text">{props.error}</p>}
            {props.successIcon && <div className="form-group--navigation"><img className="input-success" src={iconSucc} alt=""/></div>}
        </div>
    </div>
}
