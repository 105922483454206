import React, {useEffect} from "react";
import {useStore} from "../../core/providers/global_provider";
import './style.scss';
import {observer} from "mobx-react";
import close from '../../../assets/images/close-modal.svg';

export interface INotification {
    title: string;
    type: "success" | "error",
}

export const NotificationStatus = observer(()=> {

    let timer;
    const {notif, notifReset} = useStore();
    const onClose = () => {
        notifReset();
        clearTimeout(timer);
    }

    useEffect(() => {
       if (notif.title) {
           timer = setTimeout(()=>{
               onClose();
               clearTimeout(timer)
           }, 6000)
       } else {
           clearTimeout(timer)
       }
    }, [notif.title])


    return <>
        {notif && notif.title != null &&
            <div className={`${notif?.type === 'success' ? 'success' : 'error'} notification_status`}>
                {notif?.title}
                <img src={close} alt="" onClick={() => onClose()}/>
            </div>
        }
    </>
})
