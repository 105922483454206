import React, {useEffect} from "react";
import './style.scss';

interface ITextType {
    period: string;
    text: string[];
}

class textTyping {
    toRotate: string;
    el: HTMLElement;
    loopNum = 0;
    period: any;
    txt = '';
    isDeleting = false


    constructor(private elem, private rotate,  private per,) {
        this.toRotate = rotate;
        this.el = elem;
        this.period = parseInt(per, 10) || 2000;
        this.tick();
    }

    tick() {
        let i = this.loopNum % this.toRotate.length;
        let fullTxt = this.toRotate[i];

        if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">'+this.txt+'<span class="separator">|</span></span>';

        let that = this;
        let delta = 100 - Math.random() * 100;

        if (this.isDeleting) { delta /= 2; }

        if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
        }

        setTimeout(function() {
            that.tick();
        }, delta);
    }
}

export const TextTyping: React.FunctionComponent<ITextType> = (props) => {
    useEffect(() => {
        const timeoutID = window.setTimeout(() => {
            initText()
        }, 1000);

        return () => window.clearTimeout(timeoutID);
    }, [],)

    const initText = () => {
        const elements = document.getElementsByClassName('typewrite')[0];
        new textTyping(elements, props.text, props.period);
    }
    return <>
        <span className="typewrite"/>
    </>
}
