import React, {useEffect, useState} from "react";
import './style.scss'
import {InputText} from "../inputs/input_text/input_text";
import {useStore} from "../../core/providers/global_provider";
import {subscribe} from "../../core/services/urls";

export const Subscribe = (props) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [valid, setIsValid] = useState(false);
    const {setSnackError} = useStore()
    const RegEXP = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
    useEffect(() => {
        checkValidation()
    }, [email])
    const onEmailChange = (value: string) => {
        const errorMess = !value.match(RegEXP) && value.length > 0 ? 'Email is not valid' : '';
        setEmailError(errorMess);
        setEmail(value);
    }
    const checkValidation = () => {
        setIsValid( email.length > 0 && !emailError.length)
    }
    const PostUsn = async (url: string, email: string = '') => {
        const response =  await fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    "email": email
                })
            })
        const resp = await response.json()
        if (response.status === 200) {
            return resp;
        } else {
            console.log('Doesnt work',resp);
            throw resp;
        }
    }
    const onSubmit = (e) => {
        if(valid) {
            PostUsn(subscribe, email).then(value => {
                if(value.alreadyAdded) {
                    setSnackError('You are already subscribed', 'success')
                } else {
                    setSnackError('Welcome, thank you for subscribing! We’ve added you to the newsletter and will include you on the next circulation.', 'success')
                }
            }).catch((e)=>{
                setSnackError("Failed to add you to the list, please try again later or go directly to the website", 'error')
            })
        }
        setEmail('');
    }
    return <>
        <div className="subscribe-form">
            <InputText value={email} name="email" type='email'
                       label="" placeholder="info@gmail.com"
                       error={emailError}
                       onChange={(e: any) => onEmailChange(e.target.value)}
                       onClear={() => onEmailChange('')}
            />
            <button type="submit" disabled={valid ? false : true} className={`btn btn-green ${!valid && 'disabled'}`} onClick={onSubmit}>Subscribe</button>
        </div>
    </>
}
