import React, {useContext, useEffect, useState} from "react";
import {Switch,Route} from "react-router";
import {LandingUsnPage} from "./views/landing";
import {Page404} from "./views/page404";

const baseUrl = 'https://www.sparrowfi.com/'
export const landingUrl = '/';
export const privacyUrl = baseUrl + 'privacy';
export const termsUrl = baseUrl + 'terms';
export const studentUrl = baseUrl + 'student-loan-info';
export const studentRefiUrl = baseUrl + 'student-loan-refi';
export const studentManagementUrl = baseUrl + 'student-loan-management';
export const studentLearnUrl = baseUrl + 'learn';
export const aboutUrl = baseUrl + 'about-us';
export const blogUrl = baseUrl + 'blog';
export const faqUrl = baseUrl + 'faq';
export const contactUrl = baseUrl + 'contact';
export const universitiesUrl = baseUrl + 'partner/universities';
export const lenderUrl = baseUrl + 'partner/institutions';
export const employerUrl = baseUrl + 'partner/employers';


export const usnTermsUrl = "https://www.usnews.com/info/features/terms";
export const usnPrivacyUrl = "https://www.usnews.com/features/info/privacy";
export const californiaPrivacyNoteUrl = 'https://www.usnews.com/features/info/privacy/ccpa';
export const californiaPersonalInfoUrl = 'https://www.usnews.com/features/info/privacy/ccpa/do-not-sell';

export const Routes = () => (
    <Switch>
        <Route exact path={landingUrl} component={LandingUsnPage}/>
        <Route component={Page404}/>
    </Switch>
)

