import {action, makeObservable, observable} from "mobx";
import {createContext,  useContext} from "react";
export class GlobalStore {

    constructor() {
        makeObservable(this);
    }

    @observable notif = {
        title: null,
        type: null
    };

    setSnackError = (title, type) => {
        this.notifSet(title, type);
    };

    @action notifSet = (title, type) => {
        this.notif.title = title;
        this.notif.type = type;
    }

    @action notifReset = () => {
        this.notif = {
            title: null,
            type: null
        }
    }
    @observable loading = false;

    @action setLoading = (state) => {
        this.loading = state;
    }

    @observable modal = {
        child: null,
        blur: false
    };

    setModal = (child, blur = false) => {
        this.modalSetData(child, blur);
    };

    @action modalSetData = (child, blur) => {
        this.modal.child = child;
        this.modal.blur = blur;
    }

    @action modalResetData = () => {
        this.modal = {
            child: null,
            blur: false
        }
    }
}

export const GlobalStoreContext = createContext<GlobalStore>({} as GlobalStore);
export const useStore = () => useContext(GlobalStoreContext);
