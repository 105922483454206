import React, {useState} from "react";
import './poup-text.scss';
interface IInfoModal {
    title?: string;
    size?: string;
    blur? : boolean;
    onClose: ()=> void;
}


export const AppModal:React.FunctionComponent<IInfoModal> = (props) => {
    const clickOutside = (e: any) => {
        if (e.currentTarget === e.target){
            props.onClose();
        }
    }
    return <>
        <div className={`popup-wrapper app-modal ${props.blur ? 'modal-blur' : ''}`} onClick={clickOutside}>
            <div className='popup'>
                <div className="popup-content">
                    {props.children}
                </div>
            </div>
        </div>
    </>
}
