import React from "react";
import './style.scss';
import Lottie from 'react-lottie';
import dataAnim from "./animation/data2.json";
import {useStore} from "../../core/providers/global_provider";
import {observer} from "mobx-react";

export const Loading = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: dataAnim,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return <>
        <div className={`${props.shown ? 'active' : ''} loader-wrapper`}>
            <div className="loader">
                <div id="logo-animation">
                    <Lottie options={defaultOptions}/>
                </div>
                <span/>
            </div>
        </div>
    </>
}

export const Loader = observer(() => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: dataAnim,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const {loading} = useStore();
    console.log(loading);

    return <>
        <div className={`${loading ? 'active' : ''} loader-wrapper`}>
            <div className="loader">
                <div id="logo-animation">
                    <Lottie options={defaultOptions}/>
                </div>
                <span/>
            </div>
        </div>
    </>
})
