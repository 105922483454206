import React, {useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/global.scss';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Routes} from "./app/Routes";
import {GlobalStore, GlobalStoreContext, useStore} from './app/core/providers/global_provider'
import {Amplify, Auth} from "aws-amplify";
import {createBrowserHistory} from 'history';
import ScrollToTop from "./app/components/scrollTopRoute";
import {GlobalModal} from "./app/components/modals/globalModal";
import {Loader} from "./app/components/loading";
import {NotificationStatus} from "./app/components/notification_status";


const store = new GlobalStore();
export const GlobalProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    return (
        <GlobalStoreContext.Provider value={store}>
            {children}
        </GlobalStoreContext.Provider>
    );
};

const App = () => {


    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    },[]);

    return <>
        <BrowserRouter>
            <ScrollToTop/>
            <Routes/>
        </BrowserRouter>
        <GlobalModal/>
        <Loader/>
        <NotificationStatus/>
    </>
}

const history = createBrowserHistory();

let app = document.getElementById('root');
if (app) {
	const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	if (path) {
		history.replace(path);
	}

    ReactDOM.render(
        <>
            <GlobalProvider>
                <App/>
            </GlobalProvider>
        </>,
        app
    );

}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
