import React, {useContext, useEffect, useState} from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import {PartnerSlider} from "../../components/partner/PartnerSlider";
import {Link} from "react-router-dom";
import './style.scss';

import {Card} from "../../components/card";
import overviewR from '../../../assets/images/landing/overview/r.png';
import overviewPhonePlaceholder from '../../../assets/images/landing/overview/phone-placeholder.png';
import overview1Phone from '../../../assets/images/landing/overview/Overview_1/phone.png';
import overview1Card from '../../../assets/images/landing/overview/Overview_1/card.png';
import overview1Card2 from '../../../assets/images/landing/overview/Overview_1/card-2.png';
import overview1Emoji from '../../../assets/images/landing/overview/Overview_1/emoji.png';
import overview2Phone from '../../../assets/images/landing/overview/Overview_2/phone.png';
import overview2Cards from '../../../assets/images/landing/overview/Overview_2/cards.png';
import overview2Card from '../../../assets/images/landing/overview/Overview_2/card.png';
import overview2Emoji from '../../../assets/images/landing/overview/Overview_2/emoji.png';
import overview2Emoji2 from '../../../assets/images/landing/overview/Overview_2/emoji-1.png';
import overview3Phone from '../../../assets/images/landing/overview/Overview_3/iphone.png';
import overview3Card from '../../../assets/images/landing/overview/Overview_3/card.png';
import overview3Emoji from '../../../assets/images/landing/overview/Overview_3/emoji.png';
import overview3Progress from '../../../assets/images/landing/overview/Overview_3/progress.png';
import overview4Phone from '../../../assets/images/landing/overview/Overview_4/iphone.png';
import overview4Card from '../../../assets/images/landing/overview/Overview_4/card-1.png';
import overview4Card2 from '../../../assets/images/landing/overview/Overview_4/card-2.png';
import overview4Card3 from '../../../assets/images/landing/overview/Overview_4/card-3.png';
import overview4Emoji from '../../../assets/images/landing/overview/Overview_4/emoji.png';
import overview4Chart from '../../../assets/images/landing/overview/Overview_4/chart.png';
import img1 from '../../../assets/images/landing/ill3x.jpg';
import img2 from '../../../assets/images/landing/ill3x-2.jpg';
import img3 from '../../../assets/images/landing/ill3x-3.jpg';
import img4 from '../../../assets/images/landing/ill3x-4.jpg';
import img5 from '../../../assets/images/landing/ill3x-5.jpg';
import img6 from '../../../assets/images/landing/ill3x-6.jpg';
import benefit1 from '../../../assets/images/landing/benefits/ill-compare.jpg';
import benefit2 from '../../../assets/images/landing/benefits/ill-crowdsource.jpg';
import benefit3 from '../../../assets/images/landing/benefits/ill-pay.jpg';
import benefit4 from '../../../assets/images/landing/benefits/ill-manage.jpg';
import Lottie from 'react-lottie';
import dataAnim from './hero-lotie/data.json';
import {Footer} from "../../components/footer";
import {TextTyping} from "../../components/textTyping";
import {Header} from "../../components/header";
import {SubscribeUsn} from "../../components/subscribe/usn_subscribe";


export const LandingUsnPage = () => {
    const [renderPage, setRenderPage] = useState(false);

    useEffect(()=>{
        let timer = setTimeout(()=>{
            setRenderPage(true);
        },600);
        return () => {
            clearTimeout(timer);
        }
    },[renderPage]);
    window.dispatchEvent(new Event('resize'));
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: dataAnim,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const textTyping = [ "Student Loans", "Student Loan Refinancing", "Student Loan Payment", "Student Loan Savings", "Financial Literacy"];

    return <>
        <div className="header-scroll">
            <Header/>
        </div>
        <ReactFullpage
            //fullpage options
            licenseKey = {'E375C282-63564B3E-BD04263B-BA5809AD'}
            scrollingSpeed = {1000} /* Options here */
            scrollOverflow = {true}
            verticalCentered = {false}
            responsiveWidth = {990}
            normalScrollEvents = {true}
            onLeave = {function(origin, destination, direction){
                let additionalHeader = document.getElementsByClassName('header-scroll')[0];
                if(window.window.innerWidth > 990) {
                    let fpOverview = document.getElementsByClassName('fp-overview');
                    let overviewAnimationImage = fpOverview[0].getElementsByClassName('overview-animation-image');
                    if((destination.item.className.includes('section-overview-fp') && direction =='down') || (destination.item.className.includes('section-overview-fp') && direction =='up')) {
                        let oveviewID = destination.item.getElementsByClassName('overview-info')[0].attributes[1].value;
                        [].forEach.call(overviewAnimationImage, function(el:HTMLElement) {
                            let dataOverview = el.getAttribute('data-overview');
                            if(dataOverview != oveviewID) {
                                el.classList.remove('active');
                            } else {
                                el.classList.add('active');
                            }
                        });
                        fpOverview[0].classList.add('active');
                        additionalHeader.classList.add('active');
                    } else {
                        fpOverview[0].classList.remove('active');
                    }
                    if(destination.isFirst) {
                        additionalHeader.classList.remove('active');
                    }
                } else {
                    additionalHeader.classList.remove('active');
                    if(destination.item.className.includes('section-overview-fp')) {
                        let overImg = destination.item.getElementsByClassName('overview-image');
                        destination.item.getElementsByClassName('overview-image')[0].classList.add('active');
                    }
                }

            }}

            render={({ state, fullpageApi }) => {
                return (
                    <ReactFullpage.Wrapper>

                        <div className="section fp-auto-height fp-auto-height-responsive">
                            <Header/>
                            <section className="section-content section-hero">
                                <div className="section-inner">
                                    <div className="container">
                                        <div className="section-hero-info">
                                            <div className="labels">
                                                <div className="grey-label">Sparrow Teams Up with U.S. News & World Report</div>
                                            </div>
                                            <h1 className="h1">
                                                Conquer Your <br/> <TextTyping period={'3000'} text={textTyping}/>
                                            </h1>
                                            <p className="muted">Stop wasting time and money - Sparrow helps you find the best deal possible on your loans</p>
                                            <div className="button-group">
                                                <SubscribeUsn/>
                                            </div>
                                        </div>
                                        <div className="section-hero-illustration">
                                            <div className="section-hero-illustration-block">
                                                <div id="hero-animation">
                                                    <Lottie options={defaultOptions}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section-content section-partners">
                                <div className="section-inner">
                                    <div className="container">
                                        <div className="section-title text-center">
                                            <div className="section-title-top">our partners</div>
                                            <div className="section-title-main h2">Compare top lenders through one application</div>
                                        </div>
                                    </div>
                                    {renderPage && <PartnerSlider/>}
                                </div>
                            </section>
                            <section className="section-content section-benefits">
                                <div className="section-inner">
                                    <div className="container">
                                        <div className="section-title text-center"
                                             data-aos="fade-up"
                                             data-aos-duration="1000"
                                        >
                                            <div className="section-title-top">CHECK YOUR PERSONALIZED RATE</div>
                                            <div className="section-title-main h2">Applying takes less than 3 minutes
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="benefit-col col-4">
                                                <Card img={img1} title="Undergraduate Loan" link={''} disabled={false} onSubmit={() => {}}/>
                                            </div>
                                            <div className="benefit-col col-4">
                                                <Card img={img2} title="Graduate Loan" link={''} disabled={false} onSubmit={() => {}}/>
                                            </div>
                                            <div className="benefit-col col-4">
                                                <Card img={img3} title="Refinance Student Debt" disabled={false} link={''} onSubmit={() => {}}/>
                                            </div>
                                            <div className="benefit-col col-4">
                                                <Card img={img4} title="MBA Loan" link={''} disabled={false} onSubmit={() => {}}/>
                                            </div>
                                            <div className="benefit-col col-4">
                                                <Card img={img5} title="International Student Loan" link={''} disabled={false} onSubmit={() => {}}/>
                                            </div>
                                            <div className="benefit-col col-4">
                                                <Card img={img6} title="International Refinance" link={''} disabled={false} onSubmit={() => {}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section-content section-benefits">
                                <div className="section-inner">
                                    <div className="container">
                                        <div className="section-title text-center">
                                            <div className="section-title-top">we’ve got your back</div>
                                            <div className="section-title-main h2">We’ve got you covered <br/> From loan origination through your final payment</div>
                                        </div>
                                        <div className="row">
                                            <div className="benefit-col col-3">
                                                <div className="benefit">
                                                    <div className="benefit-image">
                                                        <img src={benefit1} alt=""/>
                                                    </div>
                                                    <div className="benefit-info">
                                                        <h4 className="h4">Compare</h4>
                                                        <p>Personalized rates from lenders through one application.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="benefit-col col-3">
                                                <div className="benefit">
                                                    <div className="benefit-image">
                                                        <img src={benefit2} alt=""/>
                                                    </div>
                                                    <div className="benefit-info">
                                                        <h4 className="h4">Enable</h4>
                                                        <p>Self-Driving Savings, an AI powered student loan management tool.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="benefit-col col-3">
                                                <div className="benefit">
                                                    <div className="benefit-image">
                                                        <img src={benefit3} alt=""/>
                                                    </div>
                                                    <div className="benefit-info">
                                                        <h4 className="h4">Centralize</h4>
                                                        <p>Payment of all of your student loans into one environment.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="benefit-col col-3">
                                                <div className="benefit">
                                                    <div className="benefit-image">
                                                        <img src={benefit4} alt=""/>
                                                    </div>
                                                    <div className="benefit-info">
                                                        <h4 className="h4">Automate</h4>
                                                        <p>Your loan servicing through Sparrow Auto-Pay and never miss a payment again.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="section section-overview-fp">
                            <section className="section-content section-overview">
                                <div className="section-inner">
                                    <div className="container">
                                        <div className="overview-blocks">
                                            <div className="overview-images">
                                                <img src={overviewR} alt="" className="round-bg" />

                                                <div className="overview-image overview-image-1"
                                                     data-overview="overview1">
                                                    <div className="overview-animation">
                                                        <img src={overviewPhonePlaceholder}
                                                             alt="" className="mockup-bg"/>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-1">
                                                            <img
                                                                src={overview1Phone}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-2">
                                                            <img
                                                                src={overview1Card}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-3">
                                                            <img
                                                                src={overview1Card2}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-5">
                                                            <img
                                                                src={overview1Emoji}
                                                                alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overview-infos">
                                                <div className="overview-info" id="overview1">
                                                    <div className="overview-info-title-top title-top">RECEIVE REAL
                                                        RATES
                                                    </div>
                                                    <div className="overview-info-title-main h1">Compare Real
                                                        Pre-Qualified Rates
                                                    </div>
                                                    <p className="muted">Through one application, receive real
                                                        pre-qualified rates from multiple lenders with no impact on your
                                                        credit score.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="section section-overview-fp">
                            <section className="section-content section-overview">
                                <div className="section-inner">
                                    <div className="container">
                                        <div className="overview-blocks">
                                            <div className="overview-images">
                                                <img src={overviewR} alt="" className="round-bg"/>
                                                <div className="overview-image overview-image-2"
                                                     data-overview="overview2">
                                                    <div className="overview-animation">
                                                        <img src={overviewPhonePlaceholder}
                                                             alt="" className="mockup-bg"/>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-1">
                                                            <img
                                                                src={overview2Phone}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-2">
                                                            <img
                                                                src={overview2Cards}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-3">
                                                            <img
                                                                src={overview2Card}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-4">
                                                            <img
                                                                src={overview2Emoji2}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-5">
                                                            <img
                                                                src={overview2Emoji}
                                                                alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overview-infos">
                                                <div className="overview-info" id="overview2">
                                                    <div className="overview-info-title-top title-top">BORROW ON YOUR
                                                        TERMS
                                                    </div>
                                                    <div className="overview-info-title-main h1">Loans That Work Around Your Schedule</div>
                                                    <p className="muted">Whether you need a loan right now or a year from now, loans on Sparrow accomodate for your schedule.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="section section-overview-fp">
                            <section className="section-content section-overview">
                                <div className="section-inner">
                                    <div className="container">
                                        <div className="overview-blocks">
                                            <div className="overview-images">
                                                <img src={overviewR} alt="" className="round-bg"/>
                                                <div className="overview-image overview-image-3"
                                                     data-overview="overview3">
                                                    <div className="overview-animation">
                                                        <img src={overviewPhonePlaceholder}
                                                             alt="" className="mockup-bg"/>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-1">
                                                            <img
                                                                src={overview3Phone}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-2">
                                                            <img
                                                                src={overview3Progress}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-3">
                                                            <img
                                                                src={overview3Card}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-5">
                                                            <img
                                                                src={overview3Emoji}
                                                                alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overview-infos">
                                                <div className="overview-info" id="overview3">
                                                    <div className="overview-info-title-top title-top">CENTRALIZE DEBT
                                                        PAYMENT
                                                    </div>
                                                    <div className="overview-info-title-main h1">Pay All of Your Student
                                                        Loans in One Place
                                                    </div>
                                                    <p className="muted">No matter the servicer, from private to public
                                                        student loans, centralize all your student debt payment in one
                                                        account.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="section section-overview-fp">
                            <section className="section-content section-overview">
                                <div className="section-inner">
                                    <div className="container">
                                        <div className="overview-blocks">
                                            <div className="overview-images">
                                                <img src={overviewR} alt="" className="round-bg"/>
                                                <div className="overview-image overview-image-4"
                                                     data-overview="overview4">
                                                    <div className="overview-animation">
                                                        <img src={overviewPhonePlaceholder}
                                                             alt="" className="mockup-bg"/>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-1">
                                                            <img
                                                                src={overview4Phone}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-2">
                                                            <img
                                                                src={overview4Chart}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-3">
                                                            <img
                                                                src={overview4Card}
                                                                alt=""/>
                                                        </div>

                                                        <div
                                                            className="overview-animation-image overview-animation-image-5">
                                                            <img
                                                                src={overview4Emoji}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-4">
                                                            <img
                                                                src={overview4Card2}
                                                                alt=""/>
                                                        </div>
                                                        <div
                                                            className="overview-animation-image overview-animation-image-6">
                                                            <img
                                                                src={overview4Card3}
                                                                alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overview-infos">
                                                <div className="overview-info" id="overview4">
                                                    <div className="overview-info-title-top title-top">SELF-driving
                                                        savings
                                                    </div>
                                                    <div className="overview-info-title-main h1">Automate Your Student
                                                        Loan Savings
                                                    </div>
                                                    <p className="muted">Set and forget. Configure an automatic payment
                                                        schedule for each loan, and let Sparrow save you time and
                                                        money.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="section fp-auto-height fp-auto-height-responsive">
                            <Footer/>
                        </div>
                    </ReactFullpage.Wrapper>
                );
            }}
        />
        <div className="fp-overview">
            <div className="container">
                <div className="overview-images">
                    <img src={overviewR} alt="" className="round-bg" />
                    <div className="phone-placeholder">
                        <img src={overviewPhonePlaceholder} alt="" className="mockup-bg" />
                        {/*// Overview 1 */}
                        <div
                            className="overview-animation-image overview-animation-image-content active overview-animation-image-1"
                            data-overview="overview1">
                            <img src={overview1Phone} alt="" />
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-cart active overview-animation-image-2"
                            data-overview="overview1">
                            <img src={overview1Card} alt="" />
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-cart2 active overview-animation-image-3"
                            data-overview="overview1">
                            <img src={overview1Card2} alt="" />
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-emoji active overview-animation-image-5"
                            data-overview="overview1">
                            <img src={overview1Emoji} alt="" />
                        </div>

                        {/*// Overview 2 */}
                        <div
                            className="overview-animation-image overview-animation-image-content overview-animation-image-6"
                            data-overview="overview2">
                            <img src={overview2Phone} alt="" />
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-cart overview-animation-image-7"
                            data-overview="overview2">
                            <img src={overview2Cards} alt="" />
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-cart2 overview-animation-image-8"
                            data-overview="overview2">
                            <img src={overview2Card} alt=""/>
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-emoji overview-animation-image-9"
                            data-overview="overview2">
                            <img src={overview2Emoji2} alt=""/>
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-emoji2 overview-animation-image-10"
                            data-overview="overview2">
                            <img src={overview2Emoji} alt=""/>
                        </div>

                        {/*// Overview 3 */}
                        <div
                            className="overview-animation-image overview-animation-image-content overview-animation-image-11"
                            data-overview="overview3">
                            <img src={overview3Phone} alt=""/>
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-cart2 overview-animation-image-12"
                            data-overview="overview3">
                            <img src={overview3Progress} alt=""/>
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-cart overview-animation-image-13"
                            data-overview="overview3">
                            <img src={overview3Card} alt=""/>
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-emoji overview-animation-image-14"
                            data-overview="overview3">
                            <img src={overview3Emoji} alt=""/>
                        </div>

                        {/*// Overview 4 */}
                        <div
                            className="overview-animation-image overview-animation-image-content overview-animation-image-15"
                            data-overview="overview4">
                            <img src={overview4Phone} alt=""/>
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-cart overview-animation-image-16"
                            data-overview="overview4">
                            <img src={overview4Chart} alt=""/>
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-cart2 overview-animation-image-17"
                            data-overview="overview4">
                            <img src={overview4Card} alt=""/>
                        </div>

                        <div
                            className="overview-animation-image overview-animation-image-emoji overview-animation-image-18"
                            data-overview="overview4">
                            <img src={overview4Emoji} alt=""/>
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-emoji overview-animation-image-19"
                            data-overview="overview4">
                            <img src={overview4Card2} alt=""/>
                        </div>
                        <div
                            className="overview-animation-image overview-animation-image-cart2 overview-animation-image-20"
                            data-overview="overview4">
                            <img src={overview4Card3} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
