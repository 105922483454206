import React from "react";
import {useHistory} from "react-router";

interface ICard {
    img: string;
    title: string;
    link: string;
    disabled?: boolean;
    onSubmit:() => void;
}

export const Card: React.FunctionComponent<ICard> = (props) => {

    const history = useHistory();

    const onClick = () => {
        if (!props.disabled){
            history.push(props.link);
        }
        props.onSubmit()
    }

    return <>
        <div className={`item ${props.disabled ? 'disabled': ''}`} onClick={() => onClick()}>
            <img src={props.img} alt=""/>
            <h5>{props.title}</h5>
        </div>
    </>
}
