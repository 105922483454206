import React from "react";
import './style.scss'
import {landingUrl} from '../../Routes';
import {Header} from "../../components/header";
import {Footer} from "../../components/footer";
import '../../../assets/styles/typography.scss'
import {Link} from 'react-router-dom';
import rGreen from '../../../assets/images/404/r-green-back.svg';
import ill from '../../../assets/images/404/illustration.png';

export const Page404 = () => {
    return <>
        <Header/>
        <section className="page-404">
            <div className="container">
                <div className="row">
                    <div className="col-6 col-404-text">
                        <h1 className="h1">Sorry, We Could’t Find That</h1>
                        <p className="medium">That page doesn’t exist, let’s get you back to somewhere safe.</p>
                        <Link to={landingUrl} className='btn-primary'>
                            Take me home
                        </Link>
                    </div>
                    <div className="col-6 col-404-image">
                        <div className="ill-404">
                            <img src={rGreen} alt="" className="sphere"/>
                            <img src={ill} alt="" className="illustration-404"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </>
}
